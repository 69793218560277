@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900");
:root {
  --primary-color: #774cff;
  --dark-color: #643fdb;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: "Roboto", sans-serif;
  color: #202020;
  background-color: #f7f8fa;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  margin: 0;
  padding: 0;
}

/* Scroll bar custom style */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #c6c4c4;
}

*::-webkit-scrollbar-thumb {
  background-color: #848484d1;
  outline: 0px solid #ffffff;
}

/*  */

/* Mixins ============================================ */
/* Darkmode ============================================ */
.darkmode header {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  background-color: #282828;
  border-color: transparent;
}
/* Header ============================================ */
header {
  border-bottom: solid 1px #a2a2a2;
  background-color: #ffffff;
  -webkit-transition: height 200ms ease-in;
  transition: height 200ms ease-in;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  height: 44px;
  z-index: 400;
  position: fixed;
  top: 0;
  width: 100%;
}

header nav {
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  /* max-width: 922px; */
  margin: auto;
  height: 44px;
}

header nav p,
header nav li {
  color: white;
}
header nav div.logo {
  padding-left: 8px;
  grid-area: 1;
}
header nav div.logo img {
  width: 24px;
}
header nav div.settings {
  grid-area: 0.5;
  text-align: right;
}
header nav div.settings ul {
  float: right;
  margin-right: 34px;
}
header nav div.settings ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style-type: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  float: left;
}
header nav div.settings ul li:hover {
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.2);
}
header nav div.settings ul li.settings__add {
  margin-right: 15px;
  font-size: 30px;
}
header nav div.settings ul li.settings__add button {
  background-color: transparent;
  border: 0;
  color: black;
  cursor: pointer;
}
header nav div.settings ul li.settings__darkmode button {
  background-color: transparent;
  border: 0;
  color: black;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
header nav div.settings ul li.settings__darkmode button svg {
  width: 20px;
  height: 20px;
}
.settings ul > li:hover {
  background: #555;
}
@media (max-width: 900px) {
  header {
    padding: 0 10px;
  }
  header nav {
    grid-template-columns: 0fr 1fr;
  }
  header nav div.settings ul {
    margin-right: 0;
  }
}
/* Sidebar ============================================ */
.sidebar {
  background: white;
  overflow: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 266px;
  height: calc(100vh);
  padding-top: 74px;
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: 1px solid #f1f1f1;
  background-color: #fafafa;
}
@media (max-width: 900px) {
  .sidebar {
    display: none;
  }
}
.sidebar li:hover .sidebar__project-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
}
.sidebar__project {
  cursor: pointer;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.sidebar__project div:nth-child(1) {
  padding: 5px 0;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sidebar__project-delete {
  margin-left: auto;
  display: none;
}
.sidebar__project-delete svg {
  color: #7e7e7e;
}
.sidebar__project.active,
.sidebar__project:hover {
  /* background-color: white; */
  background-color: #eceff4;

  border-radius: 5px;
}
.sidebar__project.active {
  color: #5930d9;
  font-weight: bold;
}
.sidebar__project:nth-child(1) .sidebar__dot {
  color: #6accbc;
}
.sidebar__project:nth-child(2) .sidebar__dot {
  color: #fad003;
}
.sidebar__project:nth-child(3) .sidebar__dot {
  color: #ff8d85;
}
.sidebar__project:nth-child(4) .sidebar__dot {
  color: #ff9932;
}
.sidebar__project:nth-child(5) .sidebar__dot {
  color: #af38eb;
}
.sidebar__projects {
  padding-left: 3px;
}
.sidebar__project {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  /* margin-bottom: 15px; */
}
.sidebar__dot {
  margin-right: 10px;
  /* font-size: 30px; */
  border-radius: 3px;
}
.sidebar__middle {
  margin-top: 20px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* border-bottom: 1px solid #f1f1f1; */
  padding-left: 10px;
  cursor: pointer;
}
.sidebar__middle span {
  color: #000 8a;
  margin-right: 10px;
}
.sidebar__middle span svg.hidden-projects {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.sidebar__middle h2 {
  margin: 0;
  color: #333;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 20px;
}
.sidebar__generic li {
  list-style-type: none;
  color: #333;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  line-height: 1.5;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;

  margin-left: 10px;
  margin-right: 10px;
}
.sidebar__generic li div:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 0 10px 10px;
  width: 100%;
}
.sidebar__generic li div:nth-child(1) span:first-of-type {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sidebar__generic li div:nth-child(1) span:first-of-type svg {
  width: 18px;
  height: 18px;
  color: #555;
}
.sidebar__generic li.active,
.sidebar__generic li:hover {
  /* background-color: white; */
  background-color: #eceff4;

  border-radius: 5px;
}

.sidebar__generic li.active {
  color: #5930d9;
  font-weight: bold;
}

/* Show Confirm Delete ============================================ */
.project-delete-modal {
  position: relative;
}
.project-delete-modal__inner {
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  z-index: 1;
  top: 85px;
  z-index: 99;
  padding: 10px;
  top: 10px;
  right: 0;
  background-color: white;
}
.project-delete-modal__inner p {
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: normal;
  font-weight: normal;
  font-size: 15px;
}
.project-delete-modal__inner button {
  width: "50px";
  border: 1px solid transparent;
  margin-right: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 17px;
  padding: 6px 12px 7px 12px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  border-radius: 3px !important;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
}
.project-delete-modal__inner span {
  color: #555;
  cursor: pointer;
  font-size: 14px;
  margin: 2px 5px;
  font-weight: normal;
}
/* Main Content ============================================ */
.content {
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-template-columns: 1fr auto;
  /* max-width: 922px; */
  margin: auto;
  right: 0px;
  /* padding-top: 35%; */
  position: relative;
  height: 93vh;
}
/* Tasks ============================================ */
.tasks {
  /* width: 656px; */
  /* background-color: white; */
  margin-left: 266px;
  border-right: 1px solid #f1f1f1;
  /* min-height: calc(100vh); */
  vertical-align: top;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 80px;
  padding-bottom: 84px;
  position: fixed;
  height: 100%;
}
.left-task {
  width: 64%;
  padding-right: 20%;
  left: 0;
}
.right-task {
  width: 656px;
  height: 44%;
}
@media (max-width: 900px) {
  .tasks {
    margin-left: 0;
    width: auto;
  }
  .left-task {
    width: 100%;
    padding-top: 65px;
    padding-right: 10%;
    padding-left: 10%;
  }
  .right-task {
    height: 100%;
  }
}
.tasks h2 {
  font-size: 20px;
  font-weight: normal;
  /* margin: 0 30px 20px 0; */
}
.tasks .add-task {
  margin-top: 20px;
}
.tasks__list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 18px;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  list-style-type: none;
  border-bottom: 1px solid #f0f0f0;
}
.tasks__list li .checkbox-holder {
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  /* justify-content: center; */
  line-height: 16px;
}
.tasks__list li .checkbox-holder .checkbox {
  cursor: pointer;
  border-color: gray;
  color: #343434;
  height: 16px;
  width: 16px;
  display: block;
  border: 2px solid gray;
  border-radius: 16px;
}

.checkbox .checkbox-tick {
  visibility: hidden;
}

.checkbox:hover .checkbox-tick {
  visibility: visible;
}

/* Add Task ============================================ */
.add-task__overlay {
  background: rgba(102, 102, 102, 0.5);
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  -webkit-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  height: calc(100vh);
}
.add-task__overlay .add-task__main {
  overflow: visible;
  margin-bottom: 20px;
  margin-top: 55px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 20px 30px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media (max-width: 900px) {
  .add-task__overlay .add-task__main {
    width: 100%;
    height: 100%;
  }
}
.add-task__overlay .add-task__main .header {
  font-size: 13px;
  margin-bottom: 20px;
  float: left;
}
.add-task__overlay .add-task__cancel-x {
  cursor: pointer;
  color: gray;
  float: right;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  margin-top: 10px;
  background: white;
}
.add-task__main {
  position: relative;
}
.add-task__project,
.add-task__date {
  cursor: pointer;
  float: right;
  color: gray;
  margin: 20px 10px;
}
.add-task__project {
  margin-right: 0;
}
.add-task__content {
  width: 100%;
  border: 1px solid #ddd;
  background-color: white !important;
  border-radius: 3px;
  /* height: 35px; */
  padding-left: 10px;
  padding-right: 10px;
  font-family: sans-serif;
}
.add-task__cancel,
.add-task__submit {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.add-task__cancel {
  color: #555;
  cursor: pointer;
  font-size: 14px;
  margin: 2px 5px;
}
.add-task__submit {
  width: "90px";
  background-color: #db4c3f;
  color: #fff !important;
  border: 1px solid transparent;
  margin-right: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 17px;
  padding: 6px 12px 7px 12px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  border-radius: 3px !important;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
}
.add-task__shallow,
.add-project {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-bottom: 20px;
}
.add-task__plus,
.add-project__plus {
  color: #dd4b39;
  font-size: 18px;
}
.add-task__text,
.add-project__text {
  display: inline-block;
  color: #545454;
  font-size: 14px;
  margin-left: 15px;
}
/* Task Date ============================================ */
.task-date {
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 85px;
  border-radius: 3px;
  background-color: #fff;
  width: 200px;
  right: 0;
}
.task-date__list li {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 18px;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  list-style-type: none;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 10px;
  cursor: pointer;
  padding: 0;
}
.task-date__list li:hover {
  background-color: #f9f9f9;
}
.task-date__list li div:nth-child(1) {
  padding: 10px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.task-date__list li div:nth-child(1) span:first-of-type {
  margin-right: 10px;
}
.task-date__list li div:nth-child(1) span:first-of-type svg {
  fill: grey;
}
/* Project Overlay ============================================ */
.project-overlay {
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.1) 0px 4px 11px;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 85px;
  border-radius: 3px;
  background-color: white;
}
@media (max-width: 900px) {
  .project-overlay {
    width: 200px;
    right: 0;
  }
}
.project-overlay__list li {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 18px;
  color: #333;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  list-style-type: none;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 10px;
  cursor: pointer;
  padding: 0;
}
.project-overlay__list li:hover {
  background-color: #f9f9f9;
}
.project-overlay__list li div:nth-child(1) {
  width: 100%;
  padding: 10px;
}
/* Add Project ============================================ */
.add-project {
  padding-left: 13px;
  padding-right: 20px;
  margin-top: 20px;
}
.add-project__input {
  margin-bottom: 20px;
}
.add-project__name {
  width: 100%;
  border: 1px solid #ddd;
  background-color: white !important;
  border-radius: 3px;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
}
.add-project__submit {
  width: "100px";
  background-color: #db4c3f;
  color: #fff !important;
  border: 1px solid transparent;
  margin-right: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 17px;
  padding: 6px 12px 7px 12px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  border-radius: 3px !important;
  text-decoration: none !important;
  text-align: center;
  cursor: pointer;
}
.add-project__cancel {
  color: #555;
  cursor: pointer;
  font-size: 14px;
  margin: 2px 5px;
}
.add-project__color {
  width: 30px;
  height: 30px;
}
.mui-divider-custom {
  width: 2px;
  color: gray;
  height: auto !important;
  margin-top: -10px !important;
  margin-bottom: -10px !important;
  margin-right: 10px !important;
}
.project-detail-container {
  float: right;
  display: flex;
  align-items: center;
  color: gray;
}

.project-detail-container div {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}
.profile_image {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background: #5930d9 !important;
  color: white !important;
  cursor: pointer;
}

.task-expand-text:hover {
  background: #80808027;
}

/* Default Home */
.home-main {
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  /* overflow: hidden; */
  justify-content: center;
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
}
.home-left img {
  width: 80%;
}
.home-left {
  width: 50%;
}
.home-right img {
  width: 15em;
}

.home-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4%;
  /* width: 50%; */
}
.home-center img {
  width: 79px;
}
.home-center h1 {
  font-size: 45px;
  font-weight: 100;
  margin-top: 10px;
}
.description {
  max-width: 354px;
  margin-top: 20px;
}
.interaction {
  padding: 20px 0 60px;
  max-height: 150px;
  margin-top: 20px;
}

.home-main a {
  color: white;
  font-size: 1.6rem;
  text-decoration: none;
  margin-top: 20px;
  text-transform: capitalize !important;
}
.home-center p {
  margin: 20px 0 16px;
  font-size: 24px;
  font-weight: 100;
  text-align: center;
}
.home-item-background {
  width: 50px;
  height: 50px;
  position: absolute;
  background: #774cff66;
  border-radius: 50%;
  margin-left: -7px;
  margin-top: -4px;
  z-index: -1;
}
.home-data-section {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}
.home-data-item {
  width: 22em;
  margin: 28px 26px 0px 0px;
}
.home-data {
  margin-left: 30px;
  margin-right: 30px;
}
.home-data-section img {
  width: 70px;
  z-index: 1;
}
@media (max-width: 980px) {
  .home-data-item {
    width: 15em;
  }
}
@media (max-width: 900px) {
  .home-data-item {
    width: 140px;
    margin: 28px 26px 19px 0px;
  }
  .home-left {
    width: auto;
    /* margin-bottom: -7em; */
    margin-top: 33px;
  }
  .home-left img {
    width: 100%;
  }
  .home-center img {
    width: 63px;
  }
  .home-center h1 {
    font-size: 35px;
    font-weight: 100;
    margin-top: 10px;
  }
  .home-center p {
    font-size: 20px;
  }
  .home-main a {
    font-size: 18px;
  }
}
