/* *,
*::after,
*::before__auth {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
} */

/* body__auth {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  background-color: #ecf0f3;
  color: #a0a5a8;
} */
img {
  display: unset !important;
}
button {
  background-color: none !important;
}
.main__auth {
  position: relative;
  width: 500px;
  /* min-width: 1000px; */
  /* min-height: 600px; */
  height: 600px;
  padding: 25px;
  background-color: #ecf0f3;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
}

.container__auth {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  top: 0;
  /* width: 500px; */
  height: 100%;
  padding: 25px;
  background-color: #ecf0f3;
  transition: 1.25s;
}

.form__auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
}
.form__icon__auth {
  object-fit: contain;
  width: 30px;
  margin: 0 5px;
  opacity: 0.5;
  transition: 0.15s;
}
.form__icon__auth:hover {
  opacity: 1;
  transition: 0.15s;
  cursor: pointer;
}
.form__input__auth {
  width: 350px;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  border: none;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: #ecf0f3;
  transition: 0.25s ease;
  border-radius: 8px;
  box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
}
.form__input__auth:focus {
  box-shadow: inset 4px 4px 4px #d1d9e6, inset -4px -4px 4px #f9f9f9;
}
.form__span__auth {
  margin-bottom: 12px;
}
.form__link__auth {
  color: #181818;
  font-size: 15px;
  margin-top: 25px;
  border-bottom: 1px solid #a0a5a8;
  line-height: 2;
}

.title__auth {
  font-size: 34px;
  font-weight: 700;
  line-height: 3;
  color: #181818;
  z-index: 3;
}

.description__auth {
  font-size: 14px;
  letter-spacing: 0.25px;
  text-align: center;
  line-height: 1.6;
}

.button__auth {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  margin-top: 20px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.15px;
  background-color: var(--primary-color);
  color: #f9f9f9;
  box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
  border: none;
  outline: none;
  cursor: pointer;
}

.a-container__auth {
  z-index: 100;
  left: calc(100% - 600px);
}

.b-container__auth {
  left: calc(100% - 600px);
  z-index: 0;
}

.switch__auth {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 400px;
  padding: 50px;
  z-index: 200;
  transition: 1.25s;
  background-color: #ecf0f3;
  overflow: hidden;
  box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
}
.switch__circle__auth {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #ecf0f3;
  box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
  bottom: -60%;
  left: -60%;
  transition: 1.25s;
}
.switch__circle--t__auth {
  top: -30%;
  left: 60%;
  width: 300px;
  height: 300px;
  z-index: 1;
}
.switch__container__auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 400px;
  padding: 50px 55px;
  transition: 1.25s;
}
.switch__button__auth {
  cursor: pointer;
}
.switch__button__auth:hover {
  box-shadow: 6px 6px 10px #d1d9e6, -6px -6px 10px #f9f9f9;
  transform: scale(0.985);
  transition: 0.25s;
}
.switch__button__auth:active,
.switch__button__auth:focus {
  box-shadow: 2px 2px 6px #d1d9e6, -2px -2px 6px #f9f9f9;
  transform: scale(0.97);
  transition: 0.25s;
}

.is-txr__auth {
  left: calc(100% - 400px);
  transition: 1.25s;
  transform-origin: left;
}

.is-txl__auth {
  left: 0;
  transition: 1.25s;
  transform-origin: right;
}

.is-z200__auth {
  z-index: 200;
  transition: 1.25s;
}

.is-hidden__auth {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: 1.25s;
}

.is-gx__auth {
  animation: is-gx 1.25s;
}

@keyframes is-gx__auth {
  0%,
  10%,
  100% {
    width: 400px;
  }
  30%,
  50% {
    width: 500px;
  }
}

.link-action__auth {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.link-section__auth {
  color: rgb(26, 26, 26);
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
}
.or-field__auth {
  font-size: 16px;
  margin-top: 10px;
}
